<template>
  <div class="forgot-pass-modal">
    <a-modal
      title="Forgot your Password?"
      :visible="visible"
      width="380px"
      :confirm-loading="confirmLoading"
      ok-text="Send Link"
      cancel-text="Close"
      :ok-button-props="{ props: okButtonProps }"
      @ok="handleOk"
      @cancel="handleCancel"
    >
      <div>
        <a-input v-model="context" placeholder="Username/Email" />
      </div>
    </a-modal>
  </div>
</template>
<script>

export default {
  // eslint-disable-next-line vue/require-prop-types
  props: ['visible'],
  data() {
    return {
      confirmLoading: false,
      context: '',
      okButtonProps: {},
    };
  },
  methods: {
    async handleOk() {
      this.confirmLoading = true;
      try {
        const res = await axios.post('/admin/forgot-password', { data: this.context });
        if (res.data && res.data.success) {
          this.$emit('close-modal');
        }
        this.confirmLoading = false;
      } catch (e) {
        this.confirmLoading = false;
      }
    },
    async handleCancel() {
      this.$emit('close-modal');
    },
  },
};
</script>

<style lang="scss">
.forgot-pass-modal{}
</style>
