<template>
  <div class="login-page">
    <ForgotPassModal
      :visible="isForgotModalVisible"
      @close-modal="() => isForgotModalVisible = false "
    />
    <div class="wrapper">
      <div class="left-side">
        <img src="../assets/loginPage/SB.svg" alt="">
        <img src="../assets/loginPage/smartBet.svg" alt="">
      </div>
      <div class="right-side">
        <div class="header">
          <span>Welcome Back</span>
          <span>Please enter your details to continue to Back office.</span>
        </div>
        <a-form
          id="components-form-demo-normal-login"
          :form="form"
          class="login-form"
          @submit="handleSubmit"
        >
          <a-form-item>
            <span class="item-label">Username</span>
            <a-input
              v-decorator="[
                'email',
                { rules: [{ required: true, message: 'Please input your Username!' }] },
              ]"
            >
              <a-icon slot="prefix" type="user" style="color: rgba(0,0,0,.25)" />
            </a-input>
          </a-form-item>
          <a-form-item>
            <span class="item-label">Password</span>
            <a-input-password
              v-decorator="[
                'password',
                { rules: [{ required: true, message: 'Please input your Password!' }] },
              ]"
              type="password"
            >
              <a-icon slot="prefix" type="lock" style="color: rgba(0,0,0,.25)" />
            </a-input-password>
          </a-form-item>
          <a-form-item v-if="needGoogleAuth">
            <span class="item-label">Authentication Code</span>
            <a-input
              v-decorator="[
                'googleAuthCode',
                { rules: [{ required: true, message: 'Please enter your Authentication Code!' }] },
              ]"
              type="number"
            >
              <a-icon slot="prefix" type="qrcode" style="color: rgba(0,0,0,.25)" />
            </a-input>
          </a-form-item>
          <a-form-item>
            <div class="login-footer">
              <span
                class="login-form-forgot"
                @click="() => isForgotModalVisible = true"
              >
                Forgot password?
              </span>
              <a-button type="primary" html-type="submit" class="login-form-button">
                Log in
              </a-button>
            </div>
          </a-form-item>
        </a-form>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';
import socketError from '@/utils/socketError';
import ForgotPassModal from '../components/partial/Modals/ForgotPassModal';

export default {
  components: { ForgotPassModal },
  data() {
    return {
      email: '',
      password: '',
      googleAuthCode: '',
      needGoogleAuth: false,
      wrongAuthCode: false,
      isForgotModalVisible: false,
      needUnlockUser: false,
    };
  },
  computed: {
    ...mapGetters({
      userInfo: 'info/userInfo',
    }),
    isLanguageRequestOn() {
      return process.env.VUE_APP_BLOT_CONTROL !== 'switch_blot';
    },
  },
  watch: {
    // async userInfo() {
    //   this.redirectIfAuthenticated();
    // },
    googleAuthCode() {
      if (this.wrongAuthCode) {
        this.wrongAuthCode = false;
      }
    },
  },
  beforeCreate() {
    this.form = this.$form.createForm(this, { name: 'login' });
  },
  async mounted() {
    await this.checkUnlockTokenAndPost();
    await this.redirectIfAuthenticated();
  },
  methods: {
    async checkUnlockTokenAndPost() {
      const { unlockToken } = this.$route.query;
      if (unlockToken) {
        try {
          const res = await axios.post('/unlock-user', {
            unlockToken,
          });
          return res;
        } catch (e) {
          socketError(e);
        }
      }
      return true;
    },
    async redirectIfAuthenticated() {
      if (this.userInfo.id && this.$route.path) {
        await this.$router.push('/');
      }
    },
    handleSubmit(e) {
      e.preventDefault();
      this.form.validateFields((err, values) => {
        if (!err) {
          this.login(values);
        }
      });
    },
    async login(form) {
      await this.$store.dispatch('auth/login', {
        email: form.email,
        password: form.password,
        googleAuthCode: form.googleAuthCode,
      }).then(async (e) => {
        if (e) {
          if (e.data.status === 'needGoogleAuth') {
            this.needGoogleAuth = true;
          } else if (e.data.status === 'wrongAuthCode') {
            this.form.setFields({
              googleAuthCode: {
                value: form.googleAuthCode,
                errors: [new Error('The authentication Code is incorrect')],
              },
            });
          } else if (e.data.status === 'needUnlockUser') {
            this.needUnlockUser = true;
          } else {
            await this.$store.dispatch('info/fetchUserInfo');
            if (this.isLanguageRequestOn) {
              await this.$store.dispatch('getLanguages');
            }
          }
        }
        return e;
      }).then(async ({ data }) => {
        if (data.status === 'success') {
          if (data.redirectionUrl) {
            await this.$router.push(data.redirectionUrl);
          } else {
            await this.$router.push('/');
          }
        }
      });
    },
  },
};
</script>

<style lang="scss">
.login-page {
  background-image: url('../assets/loginPage/BG.svg');
  height: 100vh;
  background-size: cover;
  display: flex;
  justify-content: center;
  align-items: center;
  .wrapper {
    display: flex;
    .left-side {
      padding: 24px;
      background: #150053;
      border-radius: 8px 0px 0px 8px;
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      img:first-child{
        width: 140px;
        margin-bottom: 24px;
      }
      img:last-child{
        width: 230px;
        margin-bottom: 24px;
      }
    }
    .right-side {
      padding: 24px 24px 0 24px;
      background: #FFFFFF;
      text-align: left;
      border-radius: 0px 8px 8px 0px;
      .header{
        margin-bottom: 32px;
        span{
          display: block;
          margin-bottom: 16px;
        }
        span:first-child{
          color: #000000;
          font-size: 24px;
          font-weight: 700;
        }
        span:last-child {
          font-size: 12px;
        }
      }
      .login-form{
        .ant-form-explain{
          font-size: 12px;
        }
        .item-label{
          position: absolute;
          bottom: 14px;
          font-size: 12px;
        }
        .login-footer{
          font-size: 12px;
          display: flex;
          justify-content: space-between;
          align-items: center;
          .login-form-forgot {
            color: #1890FF;
            cursor: pointer;
          }
        }
      }
    }
  }
}
@media (max-width: 576px) {
  .login-page {
    .wrapper {
      flex-direction: column;
    }
  }
}
</style>
